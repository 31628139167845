import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List, { ListProps } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import EmailIcon from "@mui/icons-material/Email";
import PodcastsIcon from '@mui/icons-material/Podcasts';
import YouTubeIcon from '@mui/icons-material/YouTube';
//import InstagramIcon from '@mui/icons-material/Instagram';
//import FacebookIcon from '@mui/icons-material/Facebook';

interface Props {
  window?: () => Window;
}

const drawerWidth = 240;
const navItems = ['Home'];

const MyList = styled(List)<ListProps>(({ theme }) => ({
  listStyleType: 'disc',
  pl: 8,  
  '& .MuiListItem-root': {
    display: 'list-item',
    lineHeight: 'normal',
    margin: '0px',
    padding: '0px'
  },
}));

export default function DrawerAppBar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Energy Philosopher
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
        
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            Energy Philosopher
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Button key={item} sx={{ color: '#fff' }}>
                {item}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 4 }}>
        <Toolbar />                      
        <Typography variant="body1">
          I am a philosopher of the mind, consciousness, ontology, epistemology, and technology.
        </Typography>                            
        <Typography variant="body1">
          I am obsessed with consciousness, the main vessel of our lives. 
          We need to find out why we have it, how it works, and where consciousness arises in the brain. 
          The biggest mystery in human history.<br/>
        </Typography>          
        <Typography variant="body1">
          Throughout my journey to solve the mystery of consciousness, 
          I’ve developed my ideologies, Unknownism, Valueism, Naturalism, and Psychology of Unknowism.<br/>
        </Typography>         
        <Typography variant="body1">
          I’ve captured these in an audiobook and within episodes of my podcast: 
        </Typography>
        <div>&nbsp;</div>  
        <Typography variant="h6">       
          Unknownism
        </Typography>  
        <Typography variant="body1" sx={{ pl: 2 }}>
          Unknownism is an ideology that explains the driver of everything, the temporal process of moving from unknown to known states. 
        </Typography>
        <div>&nbsp;</div>  
        <Typography variant="body1" sx={{ pl: 2 }}>
          Starting with one of the most critical questions: “Why is there something rather than nothing?” Nothing represents the unknown, and something represents the known. The “Why” has been an underlying mystery applied to any realm of our conscious experience. To explain the “Why” of everything is the transformation from unknown to known.
        </Typography>
        <div>&nbsp;</div> 
        <Typography variant="body1" sx={{ pl: 2 }}>
        Our brain, during our lives, binds more known states, about ourselves, and our external world. Our minds allow us to change, moving toward known states. There is a deep comfort in the known. As society we move towards more known states, our attempt to control our environment and live predictibltiy. Modern sciences key principle is understanding the universe, all of our leading theories in physics aim to demonstrate repeatable knowledge of everything. With the same premise, biology, and life, are formed to be known.
        </Typography>
        <div>&nbsp;</div> 
        <Typography variant="body1" sx={{ pl: 2 }}>
          The epistemological debate aims to find an absolute truth, as the source of knowledge. An absolute truth is the high state of a known. 
        </Typography>
        <div>&nbsp;</div> 
        <Typography variant="body1" sx={{ pl: 2 }}>
          The universe began to be known, as the intersection of the objective universe, and the human subjective experience. To be known implies being known to an entity. Humans only have a subjective experience of the universe, phenomena, of everything in life. The objective, the noumena, remains a mystery, however, the why of our experience, including our consciousness, is unknownism.
        </Typography>
        <div>&nbsp;</div>
        <Typography variant="body1" sx={{ pl: 2 }}>
          Why we developed consciousness, and our minds, is creating more known states. The ultimate unknown is ourselves. The goal of life starts with knowing what’s inside ourselves, we strive to understand ourselves. The next goal is to know our environment and how to live with the two.
        </Typography>
        <div>&nbsp;</div>

        <Typography variant="h6">       
          Valueism
        </Typography> 
        <Typography variant="body1" sx={{ pl: 2 }}>
          Valueism is the philosophy that everything humans do is a value exchange. The key currency we exchange is time, our limited supply. As humans, our goal is to maximize the value of our time. How we translate that value is subjective, and different for everyone, and the reason answering the question of how to live a good life is a debate for millennia.
        </Typography>
        <div>&nbsp;</div>
        <Typography variant="body1" sx={{ pl: 2 }}>
          Valueism is the core of our relationships, and partnerships, in life, primarily based on how they make us feel. We gravitate toward people who we feel add value to our lives and are looking to add value to theirs. We exchange our time at work, to add value to a company, and get value back in the form of a salary, and the company receives revenue by adding value to their customers. Money is a representation of value in our society, and sometimes time, which we exchange for material, or feeling, value.
        </Typography>
        <div>&nbsp;</div>
        <Typography variant="body1" sx={{ pl: 2 }}>
          Money is an objective representation of value. Value is relativistic and subjective. Living a good life is a question that everyone can use Valueism to explore.
        </Typography>
        <div>&nbsp;</div>

        <Typography variant="h6">       
          Naturalism
        </Typography> 
        <Typography variant="body1" sx={{ pl: 2 }}>
          Naturalism is an idealogy that every human has tendencies toward certain activities and people that feel natural. It explores the Nature versus Nurture debate from childhood to adulthood, where we derive these natural feelings, changing over time. The foundation of these feelings is the known states in our brain, the neurochemicals. 
        </Typography>
        <div>&nbsp;</div>
        <Typography variant="body1" sx={{ pl: 2 }}>
          Certain activities, music, art, dancing, and any form of human expression, feel natural. These should be explored and enhanced during our lives. There are certain people whose company feels more natural than others, these are our close friends and our life partner, from natural feelings we relate to. We should aim to explore more Naturalism in our lives.
        </Typography>
        <div>&nbsp;</div>

        <Typography variant="h6">       
          Psychology of Unknownism
        </Typography> 
        <Typography variant="body1" sx={{ pl: 2 }}>
          Following unknownism comes the Psychology of Unknownism. As humans, we have an innate fear of the unknown, very primal. The driver of our psychology, the will, is the fear of the unknown. All other emotions are based on this fear. 
        </Typography>
        <div>&nbsp;</div>

        <Typography variant="h6">
          The Consciousness Corner Podcast  
        </Typography>       
        <div>&nbsp;</div>    
          <iframe id="player_iframe" src="https://www.buzzsprout.com/2023997?client_source=small_player&iframe=true&referrer=https%3A%2F%2Fwww.buzzsprout.com%2F2023997%2Fpodcast%2Fembed" loading="lazy" width="40%" height="180" title="The Consciousness Corner"></iframe>        
        <div>&nbsp;</div> 

        <Typography variant="h6">
          Social Links
        </Typography>       
        <MyList
              sx = {{
                pl: 4,
              }}>
              <ListItem>
                Podcast: <a href="https://open.spotify.com/show/6sNeC2JcLazQO4SpaSxp3y" target="new"><PodcastsIcon fontSize="small" sx={{ color: "#E1306C" }} /></a>                  
              </ListItem>
              <ListItem>
                YouTube: <a href="https://www.youtube.com/@energyphilosopher" target="new"><YouTubeIcon fontSize="small" sx={{ color: "#FF0000" }} /></a>                  
              </ListItem>
              <ListItem>                                
                Email: <a href="mailto:energyphilosopher88@gmail.com" target="new"><EmailIcon fontSize="small" />energyphilosopher88@gmail.com</a>          
              </ListItem>              
        </MyList> 
        <div>&nbsp;</div>              
        <Typography variant="h6">       
          Philosophy of Leadership
        </Typography> 
        <Typography variant="body1" sx={{ pl: 2 }}>
          I’ve been thinking about the thought patterns of great leaders. What goes on in the mind of a great leader?
          &nbsp;
          &nbsp;
          <a href='https://www.buzzsprout.com/2023997/15370462' target='_blank' rel='noopener noreferrer'>Podcast episode</a>
        </Typography>
        <div>&nbsp;</div>

        <Typography variant="h6">       
          Transcendental Mentalism
        </Typography>   
        <Typography variant="body1" sx={{ pl: 2 }}>
          Metaphysics is a branch of philosophy dealing with ontology, theology, and the true nature of reality. Abstraction is a fundamental principle of Metaphysics. Given a desired outcome, you can use a level of abstraction to determine a path forward toward an ideal state. Following on from Transcendental Idealism, introduced by Immanuel Kant in 1781, Transcendental Mentalism(TM) constructs an evolution of thought framework for solving any problem.<br/><br/>
          Essential characteristics of successful cognitive problem solvers are their thought patterns. This paper outlines the Transcendental Mentalism method as the highest abstraction of thought, pure reason, for evolving any system.
          Transcendental Idealism is how our brains construct reality, from noumena to phenomenological experience. TM is how our minds construct evolving systems toward maximum value. Any closed system has the highest level of abstraction that is common to all environments. 

          Paper: <a href='papers/TranscendentalMentalism.pdf' target='_blank' rel='noopener noreferrer'> Transcendental Mentalism </a>
        </Typography> 
        <div>&nbsp;</div>

        <Typography variant="h6">
          Epistemology
        </Typography> 
        <Typography variant="body1" component={'span'} >
          Philosophers debated the source of knowledge, innate ideas, and Rationalism versus Empiricism for millennia. This debate has caused me much angst for a long time. I agree with Kant’s perspective that the brain creates our reality from seemingly unknown noumena, including the experience of time. Our framework of life embedded in our DNA determines the evolution of the neurochemical development of our brains. Our ideas, minds, and consciousness emerge as the phenomena of life.  
          <MyList
              sx = {{
                pl: 4,
              }}>
              <ListItem>
                The only innate universal concept is energy. 
              </ListItem>
              <ListItem>
                Emergence powers the transcendental idealism of Kant. 
              </ListItem>
              <ListItem>
                Any truth is relative to each individual. 
              </ListItem>
              <ListItem>
                Rationalism and Empiricism, thought and learning frameworks, are symmetries.
              </ListItem>
              <ListItem>
                Thought and learning frameworks change over time, as first postulated by Hegel, but nature’s randomness leads to a non-deterministic direction. 
              </ListItem>
              <ListItem>
                Hume’s mind cause and effect theory is the flow of time experience created by our brains. 
              </ListItem>
              <ListItem>
                <a href='papers/UltimateRationalist.pdf' target='_blank' rel='noopener noreferrer'> The Ultimate Rationalist </a>
            </ListItem>
          </MyList>
        </Typography>        
        <div>&nbsp;</div>

        <Typography variant="h6">       
          Time
        </Typography> 
        <Typography variant="body1" component={'span'}>
          Objectively science has no explanation for the arrow of time, and we know entropy increases in the universe. In our macro-world, we subjectively experience the past and the future. The present continuously becomes the past and is a convenient illusion. Time is the most crucial experience, and the secret of time is fundamental to other mysteries in life. 
        </Typography>
        <div>&nbsp;</div> 
        <Typography variant="body1" component={'span'}>
          Our brain creates the subjective experience of time. Our minds developed to allow us to understand time and predict the future. Time is the ultimate equalizer, and we all have the hourglass of time. Time can be your best friend or worst energy.
        </Typography>
        <div>&nbsp;</div> 
        <Typography variant="h6">       
          Technology
        </Typography> 
        <Typography variant="body1" component={'span'} >
          We are in a connection revolution fueled by our technological advancements. Like other revolutions, such as industrial and electricity, there are pros and cons. 
          The way we live our lives has exponentially changed since the internet and social media. We will soon be engaging with more Artifical General Intelligence. Technology continues to make our lives easier, and my goal is to answer if technology is making us happier.    
          <MyList
              sx = {{
                pl: 4,
              }}>
              <ListItem>
                Artificial Intelligence is rapidly evolving, and Artificial Consciousness will not happen. 
              </ListItem>
              <ListItem>
                Artifical General Intelligence will become the Artifical Zombie.  
              </ListItem>
              <ListItem>
                <a href='papers/ACandAZ.pdf' target='_blank' rel='noopener noreferrer'> Artificial Consciousness and Artificial Zombie </a>
              </ListItem>
              <ListItem>
                <a href='papers/AIPhilosophy.pdf' target='_blank' rel='noopener noreferrer'> Asked Chatgpt to create a new branch of philosophy </a>
              </ListItem>
          </MyList>
        </Typography>
        <div>&nbsp;</div> 
        <Typography variant="h6">
          Papers
        </Typography>    
        <MyList
              sx = {{
                pl: 4,
              }}>              
              <ListItem>
                <a href='papers/OntologyEmergence.pdf' target='_blank' rel='noopener noreferrer'> The Ontology of Emergence </a>
              </ListItem>
              <ListItem>
                <a href='papers/PhilosophyPhilosophy.pdf' target='_blank' rel='noopener noreferrer'> The Philosophy of Philosophy </a>
              </ListItem>
              <ListItem>
                <a href='papers/ExistentialismExists.pdf' target='_blank' rel='noopener noreferrer'> Existentialism Exists </a>               
              </ListItem> 
              <ListItem>
                <a href='papers/FearConditioning.pdf' target='_blank' rel='noopener noreferrer'>The Psychology of Fear-Based Contitioning </a>
              </ListItem>  
              <ListItem>
                <a href='papers/PsychologyEmpiricism.pdf' target='_blank' rel='noopener noreferrer'> Psychology of Empiricism </a>
              </ListItem>
              <ListItem>
                <a href='papers/UltimateRationalist.pdf' target='_blank' rel='noopener noreferrer'> The Ultimate Rationalist </a>
              </ListItem>
              <ListItem>
               <a href='papers/ACandAZ.pdf' target='_blank' rel='noopener noreferrer'> Artificial Consciousness and Artificial Zombie </a>
              </ListItem>
          </MyList>       
      </Box>
    </Box>
  );
}


