import './App.css';
import DrawerAppBar from './components/DrawerAppBar.tsx'
import React, { useEffect } from 'react';

function App() {

  useEffect(() => {
    document.title = 'Energy Philosopher';
  }, []);

  return (
   <DrawerAppBar></DrawerAppBar>
  );
}

export default App;
